var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section_header_wrapper",class:("section_header_" + _vm.align),style:(("text-align: " + _vm.align))},[_c('h1',{staticClass:"mb-3",class:{'section_header_dark': _vm.dark}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.backgroundTitle)?_c('h2',{class:{'section_header_dark': _vm.dark}},[_vm._v(" "+_vm._s(_vm.backgroundTitle)+" ")]):_vm._e(),_c('div',{staticClass:"line main_bg_color",class:{
            'sub_bg_color': _vm.dark
        }}),(_vm.subtitle)?_c('p',{staticClass:"mt-2",class:{
        'grey_text_color': _vm.dark,
        'ml-auto': _vm.right,
        'mr-auto': _vm.left,
        'mx-auto': !_vm.left && !_vm.right,
    },domProps:{"innerHTML":_vm._s(_vm.subtitle)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }