var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon_chip_wrapper",class:{
        'icon_chip_shadow': _vm.shadow && !_vm.dark,
        'icon_chip_dark_shadow': _vm.shadow && _vm.dark,
        'icon_chip_dark dark_bg_color': _vm.dark,
        'white_bg_color': !_vm.dark,
        'pointer': _vm.pointer,
        'disabled': _vm.disabled,
    },on:{"click":function($event){return _vm.submit()}}},[_c('v-icon',{class:{
            'icon_transform_direction': _vm.left
        },attrs:{"dark":_vm.dark,"size":30}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }